// src/components/Tasks.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Chip,
} from '@mui/material';
import ReactFlow, { ReactFlowProvider, MarkerType } from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { formatInTimeZone } from 'date-fns-tz';

function Tasks() {
  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [taskId, setTaskId] = useState('');
  const [tasks, setTasks] = useState([]);
  const [artifactData, setArtifactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const API_BASE_URL = 'https://bonushealth.co/api';

  // Fetch all users
  useEffect(() => {
    const fetchUsers = async () => {
      setSuccessMessage('');
      setErrorMessage('');
      try {
        const response = await fetch(`${API_BASE_URL}/sget/users`);
        const data = await response.json();
        if (data.success) {
          setUsers(data.users || []);
          setSuccessMessage('Users fetched successfully.');
        } else {
          setErrorMessage(data.error || 'Failed to fetch users.');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setErrorMessage('An error occurred while fetching users.');
      }
    };

    fetchUsers();
  }, []);

  // Fetch tasks when userId changes
  useEffect(() => {
    const fetchTasks = async () => {
      if (!userId) {
        setTasks([]);
        return;
      }
      setLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      try {
        const url = `${API_BASE_URL}/sget/tasks?user_id=${userId}`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.success) {
          setTasks(data.tasks || []);
          setSuccessMessage('Tasks fetched successfully.');
        } else {
          setTasks([]);
          setErrorMessage(data.error || 'Failed to fetch tasks.');
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setErrorMessage('An error occurred while fetching tasks.');
        setTasks([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [userId]);

  // Generate nodes and edges when tasks change
  useEffect(() => {
    if (tasks.length > 0) {
      const { nodes, edges } = generateNodesAndEdges(tasks);
      setNodes(nodes);
      setEdges(edges);
    } else {
      setNodes([]);
      setEdges([]);
    }
  }, [tasks]);

  const handleUserChange = (event) => {
    setUserId(event.target.value);
    setTaskId(''); // Reset selected task
    setArtifactData([]); // Reset artifact data
  };

  const handleTaskChange = (event) => {
    setTaskId(event.target.value);
    setArtifactData([]); // Reset artifact data
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    // No need to reset artifactData here
  };

  const handleFetchArtifact = async () => {
    if (!taskId) return;

    const selectedTask = tasks.find((task) => task.id === taskId);

    if (selectedTask) {
      const user_id = selectedTask.subject_id;
      const subfolderParts = selectedTask.subfolder.split('/');

      const program = subfolderParts[0];
      const version = subfolderParts[1];
      const lesson_number = subfolderParts[2];

      const params = new URLSearchParams();
      params.append('version', version);
      params.append('lesson_number', lesson_number);

      setLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      try {
        // Adjust the URL to include user_id and program as path variables
        const url = `${API_BASE_URL}/sget_artifacts/${user_id}/${program}?${params.toString()}`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.success) {
          setArtifactData(data.results || []);
          setSuccessMessage('Artifacts fetched successfully.');
        } else {
          console.error('Error fetching artifacts:', data.error);
          setErrorMessage(data.error || 'Failed to fetch artifacts.');
        }
      } catch (error) {
        console.error('Error fetching artifacts:', error);
        setErrorMessage('An error occurred while fetching artifacts.');
      } finally {
        setLoading(false);
      }
    }
  };

  const getUserName = (id) => {
    const user = users.find((u) => u.id === id);
    return user ? `${user.first_name} ${user.last_name}` : '';
  };

  // Function to format date to EDT timezone
  const formatDateToEDT = (utcDateStr) => {
    const date = new Date(utcDateStr);
    const timeZone = 'America/New_York';
    return formatInTimeZone(date, timeZone, 'yyyy-MM-dd HH:mm \'EDT\'');
  };

  // Function to generate nodes and edges for React Flow
  const generateNodesAndEdges = (tasks) => {
    const colorMap = {
      CANCELLED: 'red',
      UNSTARTED: 'gray',
      ONGOING: 'blue',
      COMPLETED: 'green',
    };

    const nodes = tasks.map((task) => {
      const color = colorMap[task.state] || 'black';

      const data = {
        label: (
          <div
            style={{
              padding: '10px',
              color: 'white',
              backgroundColor: color,
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            <div>{task.name || 'No Name'}</div>
            <div>{`Channel: ${task.default_channel}` || 'No Channel'}</div>
            {task.begin_timestamp && (
              <div>
                {'Begins: ' + formatDateToEDT(task.begin_timestamp)}
              </div>
            )}
            {task.end_timestamp && (
              <div>
                {'Ends: ' + formatDateToEDT(task.end_timestamp)}
              </div>
            )}
            {task.last_update && (
              <div>
                {'Last Updated: ' + formatDateToEDT(task.last_update)}
              </div>
            )}
          </div>
        ),
      };

      return {
        id: String(task.id),
        data: data,
        position: { x: 0, y: 0 }, // Positions will be set by layout
        sourcePosition: 'right',  // Added for left-right edge connection
        targetPosition: 'left',   // Added for left-right edge connection
      };
    });

    const edges = tasks
      .filter((task) => task.parent_id)
      .map((task) => {
        return {
          id: `e${task.parent_id}-${task.id}`,
          source: String(task.parent_id),
          target: String(task.id),
          type: 'smoothstep', // Set edge type to 'smoothstep' for smoother connections
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        };
      });

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges
    );

    return { nodes: layoutedNodes, edges: layoutedEdges };
  };

  // Function to layout nodes and edges using dagre
  const getLayoutedElements = (nodes, edges, direction = 'LR') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 200;
    const nodeHeight = 150;

    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    });

    return { nodes, edges };
  };

  const renderArtifact = (artifact) => {
    switch (artifact.filename) {
      case 'instructions.json':
        return renderInstructions(artifact);
      case 'metadata.json':
        return renderMetadata(artifact);
      case 'next_prompt.json':
        return renderNextPrompt(artifact);
      case 'summary.json':
        return renderSummary(artifact);
      case 'task_summary.json':
        return renderTaskSummary(artifact);
      case 'epic_summary.json':
        return renderSummary(artifact);
      case 'transcript.json':
        return renderTranscript(artifact);
      case 'metrics.json':
        return renderMetrics(artifact);
      case 'call_audio.wav':
        return renderCallAudio(artifact);
      case 'metrics_source.json':
        return renderMetricsSource(artifact);
      default:
        return renderGenericArtifact(artifact);
    }
  };

  const renderInstructions = (artifact) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">Instructions</Typography>
      <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
        {artifact.contents}
      </Typography>
    </Box>
  );

  const renderMetadata = (artifact) => {
    const metadata = artifact.contents.metadata || {};
    // Discard transcript keys
    const {
      transcript,
      transcript_object,
      transcript_with_tool_calls,
      ...restMetadata
    } = metadata;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Metadata</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {Object.entries(restMetadata).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{JSON.stringify(value)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderNextPrompt = (artifact) => {
    const { completed, intro, prompt } = artifact.contents;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Next Prompt</Typography>
        <Typography variant="body1">
          <strong>Completed:</strong> {completed ? 'Yes' : 'No'}
        </Typography>
        <Typography variant="body1">
          <strong>Intro:</strong>{' '}
          <span style={{ whiteSpace: 'pre-wrap' }}>{intro}</span>
        </Typography>
        <Typography variant="body1">
          <strong>Prompt:</strong>{' '}
          <span style={{ whiteSpace: 'pre-wrap' }}>{prompt}</span>
        </Typography>
      </Box>
    );
  };

  const renderSummary = (artifact) => {
    const { summary } = artifact.contents;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Summary</Typography>
        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
          {summary}
        </Typography>
      </Box>
    );
  };

  const renderTaskSummary = (artifact) => {
    const { summary, metrics, numeric } = artifact.contents;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Task Outputs</Typography>
        {/* Display Summary */}
        {summary && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Summary</Typography>
            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
              {summary}
            </Typography>
          </Box>
        )}

        {/* Display Metrics as a Table with Numeric Values */}
        {metrics && Array.isArray(metrics) && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Metrics</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Data Type</TableCell>
                    <TableCell>Required</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Numeric</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics.map((metric) => (
                    <TableRow key={metric.name}>
                      <TableCell>{metric.name}</TableCell>
                      <TableCell>{metric.description}</TableCell>
                      <TableCell>{metric.data_type}</TableCell>
                      <TableCell>{metric.required ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        {metric.value !== null ? metric.value : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {numeric && numeric[metric.name] !== undefined
                          ? numeric[metric.name]
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    );
  };

  const renderTranscript = (artifact) => {
    const { transcript } = artifact.contents;
    if (!transcript) return null;

    const srtContent = generateSRT(transcript);

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Transcript</Typography>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{srtContent}</pre>
      </Box>
    );
  };

  const generateSRT = (transcript) => {
    let srt = '';
    transcript.forEach((entry, index) => {
      const { role, content, words } = entry;
      let startTime = 0;
      let endTime = 0;

      if (words && words.length > 0) {
        startTime = words[0].start;
        endTime = words[words.length - 1].end;
      }

      srt += `${index + 1}\n`;
      srt += `${formatTime(startTime)} --> ${formatTime(endTime)}\n`;
      srt += `${role.toUpperCase()}: ${content}\n\n`;
    });
    return srt;
  };

  const formatTime = (seconds) => {
    const date = new Date(null);
    date.setSeconds(seconds || 0);
    const isoString = date.toISOString().substr(11, 12);
    return isoString.replace('.', ',');
  };

  const renderMetrics = (artifact) => {
    const { metrics } = artifact.contents;
    if (!metrics) return null;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Metrics</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {Object.entries(metrics).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderCallAudio = (artifact) => {
    const audioUrl = artifact.contents;
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Call Audio</Typography>
        <audio controls src={audioUrl} />
      </Box>
    );
  };

  const renderMetricsSource = (artifact) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">Metrics Source</Typography>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(artifact.contents, null, 2)}
      </pre>
    </Box>
  );

  const renderGenericArtifact = (artifact) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">{artifact.filename}</Typography>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(artifact.contents, null, 2)}
      </pre>
    </Box>
  );

  // Group artifacts by level and date
  const groupArtifactsByLevelAndDate = (artifacts, selectedLevel) => {
    const filteredArtifacts = selectedLevel
      ? artifacts.filter((artifact) => artifact.level === selectedLevel)
      : artifacts;

    const groups = {};

    filteredArtifacts.forEach((artifact) => {
      const level = artifact.level;
      const date = artifact.date
        ? new Date(artifact.date).toLocaleString()
        : 'N/A';
      const key = `${level}_${date}`;

      if (!groups[key]) {
        groups[key] = {
          level,
          date,
          artifacts: [],
        };
      }
      groups[key].artifacts.push(artifact);
    });

    return Object.values(groups);
  };

  const sortArtifacts = (artifacts, level) => {
    // Move summary artifacts to the top
    const summaryFilenames = [
      'summary.json',
      'task_summary.json',
      'epic_summary.json',
    ];
    const summaryArtifacts = [];
    // Remove summary artifacts from the artifacts array and collect them
    for (let i = artifacts.length - 1; i >= 0; i--) {
      if (summaryFilenames.includes(artifacts[i].filename)) {
        summaryArtifacts.unshift(artifacts.splice(i, 1)[0]);
      }
    }
    // Place summary artifacts at the beginning
    artifacts = [...summaryArtifacts, ...artifacts];

    if (level === 'interaction') {
      const order = [
        'summary.json',
        'call_audio.wav',
        'transcript.json',
        'metrics.json',
      ];
      artifacts = artifacts.sort((a, b) => {
        const aIndex = order.indexOf(a.filename);
        const bIndex = order.indexOf(b.filename);
        if (aIndex === -1 && bIndex === -1) {
          return a.filename.localeCompare(b.filename);
        }
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
      });
    }

    return artifacts;
  };

  const groupedArtifacts = groupArtifactsByLevelAndDate(
    artifactData,
    selectedLevel
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Tasks
      </Typography>

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* User ID Dropdown */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select User"
              value={userId}
              onChange={handleUserChange}
              fullWidth
            >
              <MenuItem value="">
                <em>All Users</em>
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Task Dropdown */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select Task"
              value={taskId}
              onChange={handleTaskChange}
              fullWidth
            >
              {tasks.map((task) => (
                <MenuItem key={task.id} value={task.id}>
                  {task.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Level Dropdown */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select Level"
              value={selectedLevel}
              onChange={handleLevelChange}
              fullWidth
            >
              <MenuItem value="">
                <em>All Levels</em>
              </MenuItem>
              <MenuItem value="interaction">Interaction</MenuItem>
              <MenuItem value="task">Task</MenuItem>
              <MenuItem value="epic">Epic</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchArtifact}
            disabled={!taskId}
          >
            Fetch Artifacts
          </Button>
        </Box>

        {/* React Flow Renderer */}
        {userId && nodes.length > 0 && (
          <Box sx={{ height: 500, mt: 4 }}>
            <ReactFlowProvider>
              <ReactFlow nodes={nodes} edges={edges} fitView />
            </ReactFlowProvider>
          </Box>
        )}

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {artifactData.length > 0 && (
          <Box sx={{ mt: 4 }}>
            {groupedArtifacts.map((group, index) => {
              const sortedArtifacts = sortArtifacts(
                group.artifacts,
                group.level
              );
              return (
                <Box
                  key={index}
                  sx={{
                    mb: 4,
                    p: 2,
                    backgroundColor: '#f5f5f5',
                    borderRadius: 2,
                  }}
                >
                  <Chip
                    label={`Level: ${group.level}, Date: ${group.date}`}
                    color="primary"
                    sx={{ mb: 2 }}
                  />
                  {sortedArtifacts.map((artifact) => (
                    <Box key={artifact.filename}>{renderArtifact(artifact)}</Box>
                  ))}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Tasks;
