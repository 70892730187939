// src/components/Schedules.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import moment from 'moment-timezone';

function Schedules() {
  const [schedules, setSchedules] = useState([]);
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [attemptsFilter, setAttemptsFilter] = useState('');

  const API_BASE_URL = 'https://bonushealth.co/api';

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    setLoadingSchedules(true);
    try {
      const url = `${API_BASE_URL}/sget/schedule`;

      const response = await fetch(url);
      const data = await response.json();
      console.log('Fetched schedules:', data);
      if (data.success) {
        setSchedules(data.schedule || []);
      } else {
        setErrorMessage(data.error || 'Failed to fetch schedules.');
      }
    } catch (error) {
      console.error('Error fetching schedules:', error);
      setErrorMessage('An error occurred while fetching schedules.');
    } finally {
      setLoadingSchedules(false);
    }
  };

  // Function to convert UTC timestamp to EDT
  const utcToEdt = (utcTimestamp) => {
    if (!utcTimestamp) return 'N/A';
    return moment
      .utc(utcTimestamp)
      .tz('America/New_York')
      .format('YYYY-MM-DD hh:mm:ss A z');
  };

  // Compute unique statuses and attempts
  const uniqueStatuses = [...new Set(schedules.map((schedule) => schedule.status))];
  const uniqueAttempts = [...new Set(schedules.map((schedule) => schedule.attempts))].sort(
    (a, b) => a - b
  );

  // Filtered schedules
  const filteredSchedules = schedules.filter((schedule) => {
    let statusMatch = true;
    let attemptsMatch = true;
    if (statusFilter) {
      statusMatch = schedule.status === statusFilter;
    }
    if (attemptsFilter !== '') {
      attemptsMatch = schedule.attempts === parseInt(attemptsFilter);
    }
    return statusMatch && attemptsMatch;
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Schedules
      </Typography>

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <TextField
          select
          label="Filter by Status"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          fullWidth
        >
          <MenuItem value="">
            <em>All Statuses</em>
          </MenuItem>
          {uniqueStatuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Filter by Attempts"
          value={attemptsFilter}
          onChange={(e) => setAttemptsFilter(e.target.value)}
          fullWidth
        >
          <MenuItem value="">
            <em>All Attempts</em>
          </MenuItem>
          {uniqueAttempts.map((attempt) => (
            <MenuItem key={attempt} value={attempt}>
              {attempt}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {loadingSchedules && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Display the schedules table */}
      {!loadingSchedules && filteredSchedules.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Schedule Results
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="schedule table">
              <TableHead>
                <TableRow>
                  <TableCell>Task ID</TableCell>
                  <TableCell>Schedule Status</TableCell>
                  <TableCell>Datetime (EDT)</TableCell>
                  <TableCell>Created (EDT)</TableCell>
                  <TableCell>Last Update (EDT)</TableCell>
                  <TableCell>Attempts</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSchedules.map((schedule) => (
                  <TableRow key={schedule.id}>
                    <TableCell>{schedule.task_id}</TableCell>
                    <TableCell>{schedule.status}</TableCell>
                    <TableCell>
                      {schedule.datetime ? utcToEdt(schedule.datetime) : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {schedule.created ? utcToEdt(schedule.created) : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {schedule.last_update ? utcToEdt(schedule.last_update) : 'N/A'}
                    </TableCell>
                    <TableCell>{schedule.attempts}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Display a message if no schedules are available */}
      {!loadingSchedules && filteredSchedules.length === 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" align="center">
            No schedules available.
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default Schedules;
