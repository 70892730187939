import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  Alert,
  Grid,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function SystemMetrics() {
  const [tabValue, setTabValue] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const API_BASE_URL = 'https://bonushealth.co/api';

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setLogs([]);
    setError('');
  };

  const handleFetchLogs = async () => {
    setLoading(true);
    setError('');
    setLogs([]);

    try {
      let url = `${API_BASE_URL}/sget_logs`;
      const params = [];
      const formatTimeWithTwoDecimals = (time) => {
        const isoString = time.toISOString();
        return isoString.split('.')[0] + 'Z'; // Removing milliseconds and adding 'Z'
      };

      if (startTime) {
        params.push(`start_time=${encodeURIComponent(formatTimeWithTwoDecimals(startTime))}`);
      }
      if (endTime) {
        params.push(`end_time=${encodeURIComponent(formatTimeWithTwoDecimals(endTime))}`);
      }

      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }

      const response = await fetch(url);
      const data = await response.json();

      if (data.success) {
        setLogs(data.logs || []);
      } else {
        setError(data.error || 'Failed to fetch logs.');
      }
    } catch (err) {
      console.error('Error fetching logs:', err);
      setError('An error occurred while fetching logs.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        System Metrics
      </Typography>

      <Box sx={{ width: '100%', mt: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Logs" />
          <Tab label="Kubernetes" />
        </Tabs>

        {tabValue === 0 && (
          <Box sx={{ mt: 4 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={4}>
                <Typography>Start Time</Typography>
                <DatePicker
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select Start Time"
                  className="date-picker"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography>End Time</Typography>
                <DatePicker
                  selected={endTime}
                  onChange={(date) => setEndTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select End Time"
                  className="date-picker"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFetchLogs}
                    disabled={loading}
                  >
                    Fetch Logs
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            )}

          {!loading && logs.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Logs:</Typography>
              <Box
                sx={{
                  maxHeight: '70vh',
                  overflow: 'auto',
                  backgroundColor: '#f5f5f5',
                  p: 2,
                  mt: 2,
                  borderRadius: 2,
                }}
              >
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                  {logs.split('\n').map((log, index) => (
                    <div key={index}>{log}</div>
                  ))}
                </pre>
              </Box>
            </Box>
          )}
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" align="center">
              Coming Soon
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default SystemMetrics;
