// src/components/MedsDashboard.js
import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

/* global google */

function MedsDashboard() {
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [userId, setUserId] = useState('');
  const [medicalRecord, setMedicalRecord] = useState(null);
  const [comment, setComment] = useState('');

  const handleCredentialResponse = (response) => {
    const decoded = jwtDecode(response.credential);
    setUser(decoded);
    setAuthorized(true);
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '424669152700-v8oukip747qq9va2tbcg26d6lfep5mkf.apps.googleusercontent.com',
      callback: handleCredentialResponse,
    });
    google.accounts.id.renderButton(
      document.getElementById('googleSignInDiv'),
      { theme: 'outline', size: 'large' }
    );
  }, []);

  const fetchMedicalRecord = () => {
    // Mock data for demonstration
    const mockRecord = {
      name: 'John Doe',
      age: 45,
      conditions: ['Hypertension', 'Diabetes'],
    };
    setMedicalRecord(mockRecord);
  };

  const addComment = () => {
    alert(`Comment added: ${comment}`);
    setComment('');
  };

  if (!authorized) {
    return <div id="googleSignInDiv"></div>;
  }

  return (
    <div className="meds-dashboard">
      <h1>Medical Dashboard</h1>
      <div>
        <label>User ID:</label>
        <select value={userId} onChange={(e) => setUserId(e.target.value)}>
          <option value="">Select User</option>
          {/* Populate options dynamically */}
          <option value="user1">User 1</option>
        </select>
        <button onClick={fetchMedicalRecord}>Get Medical Record</button>
      </div>
      {medicalRecord && (
        <div>
          <h2>Medical Record for {medicalRecord.name}</h2>
          <p>Age: {medicalRecord.age}</p>
          <p>Conditions: {medicalRecord.conditions.join(', ')}</p>
          <h3>Add Comment</h3>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows="4"
            cols="50"
          ></textarea>
          <br />
          <button onClick={addComment}>Add Comment</button>
        </div>
      )}
    </div>
  );
}

export default MedsDashboard;
