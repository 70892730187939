// src/components/Users.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';

function Users() {
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [metricsData, setMetricsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const API_BASE_URL = 'https://bonushealth.co/api';
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch users
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/users`);
        const data = await response.json();
        if (data.success) {
          setUsers(data.users || []);
        } else {
          setError(data.error || 'Failed to fetch users.');
        }
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('An error occurred while fetching users.');
      }
    };

    // Fetch courses
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget_curricula`);
        const data = await response.json();
        if (data.success) {
          const curricula = data.curricula || [];
          const courseSet = new Set();
          curricula.forEach((path) => {
            // Extract the course name from the path
            const splitPath = path.split('/');
            if (splitPath.length > 0) {
              courseSet.add(splitPath[0]);
            }
          });
          setCourses(Array.from(courseSet));
        } else {
          setError(data.error || 'Failed to fetch courses.');
        }
      } catch (err) {
        console.error('Error fetching courses:', err);
        setError('An error occurred while fetching courses.');
      }
    };

    fetchUsers();
    fetchCourses();
  }, []);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
    setMetricsData([]);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setMetricsData([]);
  };

  const handleGetMetrics = async () => {
    if (!selectedUser || !selectedCourse) {
      setError('Please select both a user and a course.');
      return;
    }

    setLoading(true);
    setError('');
    setMetricsData([]);

    try {
      const response = await fetch(
        `${API_BASE_URL}/sget_artifacts/${selectedUser}/${selectedCourse}?entity=task`
      );
      const data = await response.json();
      if (data.success) {
        const tasks = data.results || [];
        const chartData = [];

        tasks.forEach((task, index) => {
          const numericMetrics = task.contents && task.contents.numeric;
          if (numericMetrics) {
            const dataPoint = { name: `Task ${index + 1}` };
            Object.keys(numericMetrics).forEach((key) => {
              const value = numericMetrics[key];
              // Include all values, even if null
              dataPoint[key] = value;
            });
            chartData.push(dataPoint);
          }
        });

        setMetricsData(chartData);
      } else {
        setError(data.error || 'Failed to fetch metrics.');
      }
    } catch (err) {
      console.error('Error fetching metrics:', err);
      setError('An error occurred while fetching metrics.');
    } finally {
      setLoading(false);
    }
  };

  // Get all unique keys from metricsData
  const metricKeys = [];
  metricsData.forEach((dataPoint) => {
    Object.keys(dataPoint).forEach((key) => {
      if (key !== 'name' && !metricKeys.includes(key)) {
        metricKeys.push(key);
      }
    });
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        User Metrics
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* User Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Select User"
              value={selectedUser}
              onChange={handleUserChange}
              fullWidth
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Course Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Select Course"
              value={selectedCourse}
              onChange={handleCourseChange}
              fullWidth
            >
              {courses.map((course, index) => (
                <MenuItem key={index} value={course}>
                  {course}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGetMetrics}
            disabled={!selectedUser || !selectedCourse}
          >
            Get Metrics
          </Button>
        </Box>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && metricsData.length > 0 && (
          <Box sx={{ mt: 4 }}>
            {metricKeys.map((metricKey) => (
              <Box key={metricKey} sx={{ mb: 4 }}>
                <Typography variant="h6">{metricKey}</Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={metricsData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey={metricKey}
                      stroke="#8884d8"
                      dot={false}
                      connectNulls={false} // This will leave gaps for null values
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Add User Button */}
      <Box sx={{ mt: 4 }}>
        <Button variant="contained" color="secondary" onClick={() => navigate('/admin_add_user')}>
          Add User
        </Button>
      </Box>
    </Container>
  );
}

export default Users;
