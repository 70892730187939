import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material';

function Prompts() {
  const [lessonNumber, setLessonNumber] = useState(1);
  const [lessonNumbers, setLessonNumbers] = useState([...Array(28).keys()].map(i => i + 1)); // Lessons 1-28
  const [intro, setIntro] = useState('');
  const [prompt, setPrompt] = useState('');
  const [text, setText] = useState(''); // Add state for text field
  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]); // List of users
  const [courseName, setCourseName] = useState('ibt_monthly_doug/v0'); // Hardcoded course name
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetSuccessMessage, setResetSuccessMessage] = useState('');
  const [resetErrorMessage, setResetErrorMessage] = useState('');
  const [isUpdateSubmitted, setIsUpdateSubmitted] = useState(true); // Tracks if update has been submitted

  const API_BASE_URL = "https://bonushealth.co/api";

  // Fetch users for dropdown
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/users`);
        const data = await response.json();
        setUsers(data.users || []);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [API_BASE_URL]);

  // Fetch the current intro, prompt, and text for the selected lesson and course
  useEffect(() => {
    const fetchLessonData = async () => {
      setLoading(true);
      setErrorMessage(''); // Reset error message before the fetch
      try {
        const response = await fetch(`${API_BASE_URL}/sget_instructions?lesson_number=${lessonNumber}&program=${courseName}`);
        const data = await response.json();

        if (data.success) {
          // If the API indicates success, populate the intro, prompt, and text fields
          setIntro(data.intro || '');
          setPrompt(data.prompt || '');
          setText(data.text || ''); // Handle optional text field
        } else {
          // If success is false, set the error message from the response
          setErrorMessage(data.error || 'Failed to fetch lesson data.');
        }
      } catch (error) {
        console.error('Error fetching lesson data:', error);
        setErrorMessage('An error occurred while fetching lesson data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLessonData();
  }, [lessonNumber, courseName, API_BASE_URL]);

  const handleLessonChange = (event) => {
    setLessonNumber(event.target.value);
  };

  const handleCourseChange = (event) => {
    setCourseName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset messages
    setSuccessMessage('');
    setErrorMessage('');
    setResetSuccessMessage('');
    setResetErrorMessage('');

    // Prepare form data
    const formData = new URLSearchParams();
    formData.append('prompt', prompt);
    formData.append('intro', intro);
    formData.append('text', text); // Add text to form submission
    formData.append('lesson_number', lessonNumber);
    formData.append('user_id', userId);
    formData.append('program', courseName);  // Add the program to the form data

    try {
      const response = await fetch(`${API_BASE_URL}/sedit_prompt/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      const result = await response.json();

      if (result.success) {
        setSuccessMessage('Lesson updated successfully!');
        setIsUpdateSubmitted(true);  // Mark that update was successfully submitted
      } else {
        setErrorMessage(`Failed to update the lesson: ${result.error}`);
      }
    } catch (error) {
      console.error('Error updating lesson:', error);
      setErrorMessage('An error occurred while updating the lesson.');
    }
  };

  const handleReset = async () => {
    setResetLoading(true);
    setResetSuccessMessage('');
    setResetErrorMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/sreset_user/${userId}?lesson_number=${lessonNumber}`, {
        method: 'GET',
      });

      const result = await response.json();
      setResetLoading(false);

      if (result.success) {
        setResetSuccessMessage(`Successfully reset ${getUserName(userId)} from lesson ${lessonNumber}`);
        setIsUpdateSubmitted(false);
      } else {
        setResetErrorMessage(`Failed to reset the user: ${result.error}`);
      }
    } catch (error) {
      console.error('Error resetting user:', error);
      setResetErrorMessage('An unknown error occurred while resetting the user.');
      setResetLoading(false);
    }
  };

  const getUserName = (id) => {
    const user = users.find(u => u.id === id);
    return user ? `${user.first_name} ${user.last_name}` : '';
  };

  const isResetDisabled = !userId || resetLoading || !isUpdateSubmitted;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Update Lesson
      </Typography>

      {/* Messages */}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
      {resetSuccessMessage && <Alert severity="success" sx={{ mb: 2 }}>{resetSuccessMessage}</Alert>}
      {resetErrorMessage && <Alert severity="error" sx={{ mb: 2 }}>{resetErrorMessage}</Alert>}

      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          {/* Lesson Number */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select Lesson"
              value={lessonNumber}
              onChange={handleLessonChange}
              fullWidth
            >
              {lessonNumbers.map((number) => (
                <MenuItem key={number} value={number}>
                  Lesson {number}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Course Name */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Course Name"
              value={courseName}
              onChange={handleCourseChange}
              fullWidth
            >
              <MenuItem value="ibt_monthly_doug/v0">ibt_monthly_doug/v0</MenuItem>
            </TextField>
          </Grid>

          {/* User ID Dropdown */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Select User"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              fullWidth
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Intro */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Valid AND required variables include {"{name}"} and {"{therapist_name}"}.
            </Typography>
            <TextField
              label="Intro"
              value={intro}
              onChange={(e) => setIntro(e.target.value)}
              required
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>

          {/* Prompt */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              No variables allowed for prompt! Don't use curly braces!
            </Typography>
            <TextField
              label="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              required
              fullWidth
              multiline
              minRows={6}
            />
          </Grid>

          {/* Text Field */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Valid AND required variables include {"{name}"} and {"{next_appointment}"}.
            </Typography>
            <TextField
              label="Text Message (After Call)"
              value={text}
              onChange={(e) => setText(e.target.value)}
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>

          {/* Update Lesson Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            >
              Update Lesson
            </Button>
          </Grid>

          {/* Reset Button */}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              size="large"
              disabled={isResetDisabled}
              onClick={handleReset}
            >
              {resetLoading
                ? 'Resetting...'
                : `Reset ${getUserName(userId)} from lesson ${lessonNumber}`}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Loading Indicator */}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
}

export default Prompts;
