// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import AdminDashboard from './components/AdminDashboard';
import MedsDashboard from './components/MedsDashboard';
import PatientsDashboard from './components/PatientsDashboard';
import AdminAddUser from './components/AdminAddUser';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/meds" element={<MedsDashboard />} />
        <Route path="/patients" element={<PatientsDashboard />} />
        <Route path="/admin_add_user" element={<AdminAddUser />} />
      </Routes>
    </Router>
  );
}

export default App;
