import React from 'react';

function Home() {
  return (
    <div className="home">
      <h1>Welcome to Bonus Health</h1>
      <p>Your health, our priority</p>
    </div>
  );
}

export default Home;
