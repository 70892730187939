// src/components/PatientsDashboard.js
import React, { useEffect, useState, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Line } from 'react-chartjs-2';

/* global google */

function PatientsDashboard() {
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [weightData, setWeightData] = useState(null);
  const googleSignInDiv = useRef(null);

  const handleCredentialResponse = (response) => {
    const decoded = jwtDecode(response.credential);
    console.log('Decoded token:', decoded);
    setUser(decoded);
    setAuthorized(true);
    fetchWeightData();

    // Remove the Google Sign-In button from the DOM
    if (googleSignInDiv.current) {
      googleSignInDiv.current.innerHTML = '';
    }
  };

  useEffect(() => {
    console.log('Authorized:', authorized);
    if (!authorized && googleSignInDiv.current) {
      google.accounts.id.initialize({
        client_id: 'YOUR_GOOGLE_CLIENT_ID',
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(googleSignInDiv.current, {
        theme: 'outline',
        size: 'large',
      });
    }

    return () => {
      // Cleanup function
      if (googleSignInDiv.current) {
        googleSignInDiv.current.innerHTML = '';
      }
    };
  }, [authorized]);

  const fetchWeightData = () => {
    // Mock data for demonstration
    const data = {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
      datasets: [
        {
          label: 'Weight (kg)',
          data: [70, 70.5, 71, 70.8, 70.2],
          fill: false,
          backgroundColor: 'blue',
          borderColor: 'blue',
        },
      ],
    };
    setWeightData(data);
  };

  return (
    <div className="patients-dashboard">
      <div ref={googleSignInDiv}></div>
      {authorized && (
        <>
          <h1>Patient Dashboard</h1>
          {weightData && (
            <div>
              <h2>Your Weight Over Time</h2>
              <Line data={weightData} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PatientsDashboard;
