// src/components/Courses.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Box,
  Alert,
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function Courses() {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [lessons, setLessons] = useState([]);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [draggedItems, setDraggedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetUser, setResetUser] = useState(false);
  const [addTimeLimits, setAddTimeLimits] = useState(false);

  const API_BASE_URL = 'https://bonushealth.co/api';

  useEffect(() => {
    // Fetch courses
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget_curricula`);
        const data = await response.json();
        if (data.success) {
          const curricula = data.curricula || [];
          const courseSet = new Set();
          curricula.forEach((path) => {
            // Extract the course name from the path
            const splitPath = path.split('/');
            if (splitPath.length > 0) {
              courseSet.add(splitPath[0]);
            }
          });
          setCourses(Array.from(courseSet));
        } else {
          setErrorMessage(data.error || 'Failed to fetch courses.');
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
        setErrorMessage('An error occurred while fetching courses.');
      }
    };

    // Fetch users
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/users`);
        const data = await response.json();
        if (data.success) {
          setUsers(data.users || []);
        } else {
          setErrorMessage(data.error || 'Failed to fetch users.');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setErrorMessage('An error occurred while fetching users.');
      }
    };

    fetchCourses();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      const fetchLessons = async () => {
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
        try {
          const response = await fetch(
            `${API_BASE_URL}/sget_curricula?program=${selectedCourse}&contents=1`
          );
          const data = await response.json();
          if (data.success) {
            setLessons(data.curricula || []);
          } else {
            setLessons([]);
            setErrorMessage(data.error || 'Failed to fetch lessons.');
          }
        } catch (error) {
          console.error('Error fetching lessons:', error);
          setErrorMessage('An error occurred while fetching lessons.');
          setLessons([]);
        } finally {
          setLoading(false);
        }
      };
      fetchLessons();
    } else {
      setLessons([]);
    }
  }, [selectedCourse]);

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setLessons([]);
    // Do not reset draggedItems to keep selected lessons
    // setDraggedItems([]);
  };

  const handleUserChange = (event) => {
    setUserId(event.target.value);
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    // If dropped into the same list, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    if (destination.droppableId === 'selectedList') {
      // Add item to draggedItems
      const lesson = lessons.find((lesson) => lesson.title === draggableId);
      if (lesson && !draggedItems.some((item) => item.title === lesson.title)) {
        setDraggedItems((prev) => [...prev, lesson]);
      }
    } else if (destination.droppableId === 'availableList') {
      // Remove item from draggedItems
      const newDraggedItems = [...draggedItems];
      const index = newDraggedItems.findIndex((item) => item.title === draggableId);
      if (index !== -1) {
        newDraggedItems.splice(index, 1);
        setDraggedItems(newDraggedItems);
      }
    } else if (source.droppableId === 'selectedList' && destination.droppableId === 'selectedList') {
      // Reorder within draggedItems
      const newDraggedItems = Array.from(draggedItems);
      const [removed] = newDraggedItems.splice(source.index, 1);
      newDraggedItems.splice(destination.index, 0, removed);
      setDraggedItems(newDraggedItems);
    }
  };

  const handleSubmit = async () => {
    if (!userId || draggedItems.length === 0) {
      setErrorMessage('Please select a user and at least one lesson.');
      return;
    }

    const paths = draggedItems.map((item) => item.title);

    const payload = {
      user_id: userId,
      paths: paths,
    };

    if (resetUser) {
      payload.reset = true;
    }

    if (addTimeLimits) {
      payload.add_time_limits = true;
    }

    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const response = await fetch(`${API_BASE_URL}/sassign_course/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Course assigned successfully.');
        setDraggedItems([]);
      } else {
        setErrorMessage(data.error || 'Failed to set up course.');
      }
    } catch (error) {
      console.error('Error setting up course:', error);
      setErrorMessage('An error occurred while setting up course.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Courses
      </Typography>

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* Course Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Select Course"
              value={selectedCourse}
              onChange={handleCourseChange}
              fullWidth
            >
              {courses.map((course, index) => (
                <MenuItem key={index} value={course}>
                  {course}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* User Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Select User"
              value={userId}
              onChange={handleUserChange}
              fullWidth
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Reset User Checkbox */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={resetUser}
                  onChange={(e) => setResetUser(e.target.checked)}
                  color="primary"
                />
              }
              label="Reset user during this assignment"
            />
          </Grid>

          {/* Add Time Limits Checkbox */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addTimeLimits}
                  onChange={(e) => setAddTimeLimits(e.target.checked)}
                  color="primary"
                />
              }
              label="Add daily time constraints"
            />
          </Grid>
        </Grid>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Available Lessons</Typography>
              <Droppable droppableId="availableList" isDropDisabled={true}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ minHeight: 400, border: '1px solid #ccc', p: 2 }}
                  >
                    {lessons.map((lesson, index) => {
                      const prefixToRemove = 'gs://bonus-datalake/curricula';
                      const pathWithoutInstructions = lesson.title
                        .replace('/instructions.json', '')
                        .replace(prefixToRemove, '');
                      return (
                        <Draggable key={lesson.title} draggableId={lesson.title} index={index}>
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{ mb: 1 }}
                            >
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel-content-${index}`}
                                  id={`panel-header-${index}`}
                                >
                                  <Typography variant="subtitle1">
                                    {pathWithoutInstructions}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                    <strong>Intro:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.intro}
                                  </Typography>
                                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                    <strong>Prompt:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.prompt}
                                  </Typography>
                                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                    <strong>Text:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.text}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Selected Lessons</Typography>
              <Droppable droppableId="selectedList">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ minHeight: 400, border: '1px solid #ccc', p: 2 }}
                  >
                    {draggedItems.map((lesson, index) => {
                      const prefixToRemove = 'gs://bonus-datalake/curricula';
                      const pathWithoutInstructions = lesson.title
                        .replace('/instructions.json', '')
                        .replace(prefixToRemove, '');
                      return (
                        <Draggable key={lesson.title} draggableId={lesson.title} index={index}>
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{ mb: 1 }}
                            >
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel-content-selected-${index}`}
                                  id={`panel-header-selected-${index}`}
                                >
                                  <Typography variant="subtitle1">
                                    {pathWithoutInstructions}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                    <strong>Intro:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.intro}
                                  </Typography>
                                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                    <strong>Prompt:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.prompt}
                                  </Typography>
                                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                    <strong>Text:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.text}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </DragDropContext>

        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit Assignment
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Courses;
